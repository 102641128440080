import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { Button, Row, Col, Modal, Form } from 'react-bootstrap';
import api from "./AuthBearer";
import PuffLoader from "react-spinners/PuffLoader";
import { toast, ToastContainer } from 'react-toastify';
import StudentSidebar from '../components/StudentPanel/StudentSidebar/StudentSidebar';
import TutorSidebar from '../components/TutorPanel/TutorSidebar/TutorSidebar';

const override = {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
};


const convertValiditiesToDate = (tutorsArray) => {
    return tutorsArray.map(tutor => {
        if (tutor.validity && tutor.validity._seconds && tutor.validity._nanoseconds) {
            const timestamp = tutor.validity;
            const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6);

            // Replace the validity object with the formatted date
            tutor.validity = date.toLocaleString('en-IN', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        }

        return tutor;
    });
}


export {
    React,
    useState,
    useEffect,
    axios,
    API_URL,
    Button,
    Row, Col,
    Modal,
    api,
    PuffLoader,
    toast,
    ToastContainer,
    override,
    convertValiditiesToDate,
    StudentSidebar,
    TutorSidebar,
    useRef,
    Form
};