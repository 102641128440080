import {React, Form, Button, Row, Col, useRef } from "../../services/common"
import { useAuth } from "./AuthContext";
import "./Auth.css";

// Forget password page
const ForgotPassword = ({handleSuccess, handleError}) => {
    const emailRef = useRef()
    const { resetPassword } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await resetPassword(emailRef.current.value);
            handleSuccess("Check your inbox for further instructions");
        } catch {
            handleError("Unable to send email for password reset. Please check if the email address is correct")
        }
    }

    return (
        <>
            <Row>
                <Col className="auth-card">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="fields" >
                            <Form.Control className="auth-fields" type="email" placeholder="Email" ref={emailRef} required />
                        </Form.Group>
                        <Button className="w-100 submit" type="submit">
                            Reset Password
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>

    )
}

export default ForgotPassword;