import { React, useState, useRef, Form, Button, Row, Col, PuffLoader, override } from "../../services/common"
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import eye from "../../assets/images/eye.svg"
import "./Auth.css";

const Login = ({closeModal, handleSuccess, handleError}) => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login, getClaims } = useAuth();
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate();

    const fetchUserRole = () => {
        getClaims().then((idTokenResult) => {
            localStorage.setItem('uid', idTokenResult.claims.user_id);
            if (idTokenResult.claims.tutor) {
                navigate("/tutor-panel/dashboard");
            }
            else if(idTokenResult.claims.student){
                navigate("/student-panel/dashboard")
            }
            else {
                navigate("/");
            }
            handleSuccess("Welcome back! You have successfully logged in")
            closeModal()

        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value).then(() => {
                fetchUserRole();
            });
        } catch {
            handleError("Your Email or Password is Incorrect");
        }
        setLoading(false);
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <>
            <Row>
                <Col className="auth-card">
                    <Form onSubmit={handleSubmit}>
                        <div className="or-section">
                            <hr className="line">
                            </hr>
                            <span className="or">Or use your email</span>
                        </div>
                        <Form.Group className="fields">
                            <Form.Control className="auth-fields" type="email" ref={emailRef} placeholder="Email" required />
                        </Form.Group>
                        <Form.Group className="fields">
                            <Form.Control className="auth-fields" type={passwordShown ? "text" : "password"} ref={passwordRef} placeholder="Password" required />
                            <img src={eye} className="password-eye" onClick={togglePassword} />
                        </Form.Group>
                        <Button size="lg" disabled={loading} className="w-100 submit" type="submit">
                            Login
                        </Button>
                    </Form>
                </Col>
            </Row>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
        </>
    )
}

export default Login;
