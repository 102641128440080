import { useAuth } from "./AuthContext";
import { React, useState, Button, useEffect, PuffLoader, override } from "../../services/common";
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const PhoneAuth = ({closeModal, handleError, handleSuccess}) => {
    // State for storing the phone number and verification code
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const { sendPhoneVerificationCode, verifyPhoneCode } = useAuth();
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [phoneView, setPhoneView] = useState(true);
    const [otpView, setOtpView] = useState(false);
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(60); // Initial timer value in seconds
    const [loading, setLoading] = useState(false);


    const handleStartTimer = () => {
        const intervalId = startTimer();
        setTimeout(() => {
            stopTimer(intervalId);
        }, 60000);
    };

    const stopTimer = (intervalId) => {
        clearInterval(intervalId);
    };

    const startTimer = () => {
        const intervalId = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);

        return intervalId;
    };

    const getCountryCode = (phoneNumber) => {
        if (phoneNumber) {
            const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
            return phoneNumberObject ? phoneNumberObject.country : 'IN'; // Default to 'US' if country code is not found
        }
        return 'IN';
    };

    const isValidPhoneNumber = async (phoneNumber) => {
        try {
            const phoneNumberObject = parsePhoneNumberFromString(phoneNumber, getCountryCode(phoneNumber));
            return phoneNumberObject && phoneNumberObject.isValid();
        } catch (error) {
            return false;
        }
    };

    const handlePhoneChange = (number) => {
        setPhoneNumber(number);
    };

    const sendOTP = async () => {
        try {
            const phoneNumberwithCountry = "+" + phoneNumber;
            if (!await isValidPhoneNumber(phoneNumberwithCountry)) {
                handleError('Invalid phone number. Please enter a valid phone number.');
                return; // Exit the function if phone number is invalid
            }
    
            setLoading(true);
            setTimer(60);
           const result = await sendPhoneVerificationCode(phoneNumberwithCountry);
                handleSuccess(`We've send the OTP on your phone + ${phoneNumberwithCountry}`);
                setPhoneView(false);
                setOtpView(true);
                setConfirmationResult(result);
                handleStartTimer();
                setLoading(false);
            console.log(confirmationResult);
            // Save confirmation result if needed
        } catch (error) {
            handleError(error.message);
            setLoading(false);
            console.error("Error sending verification code:", error);
        }
    };

    const editPhoneNumber = () => {
        setPhoneView(true);
        setOtpView(false);
    }

    const handleVerifyCode = async () => {
        setLoading(true);
        try {
            // Use the verification code to sign in the user
            await verifyPhoneCode(
                confirmationResult.verificationId,
                otp
            )
            setLoading(false);
            handleSuccess("You have successfully authenticated")
            closeModal()
               
            
        } catch (error) {
            setLoading(false);
            handleError(error.message);
            console.error("Error verifying code:", error);
        }
    };

    return (
        <>
            <div>
                <div id="recaptcha-container"></div>
                {
                    phoneView &&
                    <div>
                        <PhoneInput country={'in'} value={phoneNumber} onChange={handlePhoneChange} required />
                        <Button onClick={sendOTP} size="lg" className="w-100 submit">
                            GET OTP
                        </Button>
                    </div>
                }
                {
                    otpView &&
                    <div>
                        <h2 className="verify-otp">Verify OTP</h2>
                        <p className="otp-text"> We have sent an OTP on +{phoneNumber}
                            <span className="edit-phone" onClick={editPhoneNumber}>Edit</span>
                        </p>
                        <div className="otp-container">
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span style={{ width: "1rem" }}></span>} inputStyle={{
                                    borderRadius: "0.5rem",
                                    border: "0.795px solid #CBD1D8",
                                    background: "#FAFAFC",
                                    width: "54px",
                                    height: "54px",
                                    caretColor: "blue"
                                }} isInputNum={true}
                                shouldAutoFocus={true}
                                focusStyle={{
                                    border: "1px solid #7F56D9",
                                    outline: "none"
                                }}
                                renderInput={(props) => <input {...props} />}
                            /></div>
                        <Button onClick={handleVerifyCode} size="lg" className="w-100 submit otp-login">
                            Login
                        </Button>

                        <div className="login-signup resend-otp">
                            {timer > 0 ? (
                                <span className="auth-text">
                                    <span className="auth-bold">Resend</span> OTP in {timer}
                                </span>
                            ) : (
                                <span className="auth-text auth-bold" onClick={sendOTP}>
                                    Resend OTP
                                </span>
                            )}
                        </div>
                    </div>
                }
                <div className="or-section">
                    <hr className="line">
                    </hr>
                    <span className="or">Or use your</span>
                </div>
            </div>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
        </>

    );
};

export default PhoneAuth;