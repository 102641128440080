import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "firebase/auth"

const app = firebase.initializeApp({
  apiKey: "AIzaSyCCo6hoEHVXtvDgVCaoD7ffZpIuBLFa5bs",
  authDomain: "musicseekho.com",
  databaseURL: "https://musicapp-backend-5878b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "musicapp-backend-5878b",
  storageBucket: "musicapp-backend-5878b.appspot.com",
  messagingSenderId: "788153552523",
  appId: "1:788153552523:web:c5e400dbf8a81fbc5dce8e",
  measurementId: "G-8Z8EKH03GL"
})

export const auth = app.auth()
export default app;
