import { React, useEffect, useState, api, API_URL, toast, ToastContainer, PuffLoader, override } from "../../../services/common";
import { Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import profile from "../../../assets/images/profile.svg";
import classcount from "../../../assets/images/class-count.svg";
import MainHeader from "../../Common/Header/MainHeader/MainHeader";



const CourseList = () => {
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();

    const viewSelectedCourse = (tutorId, course, courseName) => {
        navigate(`/courses/${tutorId}/${course}`,  { state: { courseName: courseName  } });
    }

    const viewCourses = () => {
        setLoading(true);
        api.get(API_URL + '/tutors/view-tutors').then((response) => {
            setLoading(false);
            const tutorsData = response.data;
            const allCourses = tutorsData.reduce((courses, tutor) => {
                const tutorCourses = tutor.courses.map(course => ({
                    ...course,
                    tutor_id: tutor.id,
                    tutor: tutor.name
                }));
                return [...courses, ...tutorCourses];
            }, []);
            setCourses(allCourses);
        }).catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message || error.message); ;
            console.log(error);
        });
    }

    useEffect(() => {
        viewCourses();
    }, []);


    return (
        <>
        <MainHeader></MainHeader>
            <div className="tutor-list">
                {courses.map((course, index) => (
                    <div key={index}>
                        <Card className="tutor-cards" onClick={() => viewSelectedCourse(course?.tutor_id, course?.id, course?.name)}>
                            <span className = "course-tag">{course?.name}</span>
                            <Card.Img className="tutor-image" variant="left" src={course?.image || 'https://firebasestorage.googleapis.com/v0/b/musicapp-backend-5878b.appspot.com/o/placeholder.jpg?alt=media&token=f59e4d05-d1e7-4d92-bffa-c27b1ca6941b'} />
                            <Card.Body>
                                <Card.Title className="tutor-name">
                                    {course?.description && course.description.length > 25 ?
                                        `${course.description.substring(0, 25)}...` :
                                        course.description}
                                </Card.Title>
                                <Card.Title >
                                    <img className="tutor-icons" src={profile}></img>
                                    <span className="tutor-details">{course?.tutor}</span>
                                </Card.Title>
                                <Card.Title>
                                    <img className="tutor-icons student-icon" src={classcount}></img>
                                    <span className="tutor-details">{course?.students || 0} students </span>
                                </Card.Title>
                                <div className="view-more">View More</div>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
        </>
    )
}

export default CourseList
