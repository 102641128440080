import { React, useState, useEffect, api, API_URL, PuffLoader, override, ToastContainer, toast, StudentSidebar } from "../../../services/common";
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import { Scheduler, Appointments, WeekView, AppointmentTooltip, AppointmentForm, ConfirmationDialog, TodayButton, Toolbar, DateNavigator } from '@devexpress/dx-react-scheduler-material-ui';
import Paper from '@mui/material/Paper';
import BasicLayout from "./StudentAppointment";
import "./StudentSchedule.css";

const StudentCalender = () => {
    const [loading, setLoading] = useState(false);
    const [weeklySchedule, setWeeklySchedule] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    let id = localStorage.getItem('uid');
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Asia/Kolkata' }
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const TextEditor = (props) => {
        return null;
    };

    const convertToFinalRequest = (inputObject, type) => {
        const { startDate, slot, tutor_id, tutor_name, course, is_deleted, existing_day, existing_id } = inputObject;
        const dayOfWeek = new Date(startDate).toLocaleDateString('en-IN', { weekday: 'long' });
        const [start_time, end_time] = slot.split(' - ');
        const convertedObject = {
            [dayOfWeek]: [
                {
                    start_time,
                    end_time,
                    tutor_id,
                    tutor_name,
                    course,
                    is_deleted,
                    existing_day,
                    existing_id
                }
            ]
        };
        return convertedObject;
    };


    const converttoSlot = (startDate, endDate) => {
        const startTime = (new Date(startDate)).toLocaleTimeString('en-IN', timeOptions );
        const endTime = (new Date(endDate)).toLocaleTimeString('en-IN', timeOptions);
        const dayOfWeek = daysOfWeek[new Date(startDate).getDay()];
        return {
            existing_id: `${startTime}-${endTime}`,
            existing_day: dayOfWeek,
        }
    }

    const commitChanges = async (added, changed, deleted) => {
        try {
            let data = await new Promise((resolve, reject) => {
                setWeeklySchedule((prevWeeklySchedule) => {
                    try {
                        setLoading(true);
                        let data = [...prevWeeklySchedule];
                        let body;
                        if (added.added) {
                            const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
                            data = [...data, { id: startingAddedId, ...added.added }];
                            body = added.added;
                        }

                        if (added.changed) {
                            changed = added.changed;
                            data = data.map(appointment => (
                                changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment
                            ));
                            let changedObject = Object.values(changed)[0];
                            const selectedId = Object.keys(changed)[0];
                            const selectedSchedule = prevWeeklySchedule[selectedId];
                            const { existing_id, existing_day } = converttoSlot(selectedSchedule.startDate, selectedSchedule.endDate);
                            changedObject["existing_id"] = existing_id;
                            changedObject["existing_day"] = existing_day;
                            body = changedObject;
                        }

                        if (added.deleted !== undefined) {
                            data = data.filter(appointment => appointment.id !== added.deleted);
                            let deletedObject = prevWeeklySchedule[added.deleted];
                            const startTime = (new Date(deletedObject.startDate)).toLocaleTimeString('en-IN', timeOptions );
                            const endTime = (new Date(deletedObject.endDate)).toLocaleTimeString('en-IN', timeOptions);
                            deletedObject["is_deleted"] = 1;
                            deletedObject["slot"] = `${startTime} - ${endTime}`;
                            deletedObject["course"] = deletedObject["title"];
                            delete deletedObject["title"];
                            delete deletedObject["tutor_name"];
                            body = deletedObject;
                        }
                        let finalBody = convertToFinalRequest(body);

                        api.post(API_URL + "/students/update-weekly-schedule/" + id, finalBody)
                            .then((response) => {
                                setLoading(false);
                                if (response.status !== 200) {
                                    toast.error(response.message);
                                    console.error("API error:", response);
                                    reject(new Error("API request failed"));
                                }
                                resolve(data);
                            })
                            .catch((error) => {
                                setLoading(false);
                                toast.error(error?.response?.data?.message || error.message); ;
                                console.error("Error:", error);
                                reject(prevWeeklySchedule);
                            });
                    } catch (error) {
                        setLoading(false);
                        toast.error(error?.response?.data?.message || error.message); ;
                        reject(error);
                    }
                });
            });
            setWeeklySchedule(data);
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || error.message); ;
            console.error("Error:", error);
        }
    }


    const transformData = (apiData) => {
        let idCounter = 0;
        return apiData.map((dayData) => {
            const day = Object.keys(dayData)[0];
            const schedule = dayData[day].schedule.map((event) => ({
                id: idCounter++,
                startDate: calculateStartDate(day, event.start_time),
                endDate: calculateStartDate(day, event.end_time),
                title: event?.courseName,
                course_id: event?.course,
                tutor_id: event.tutor_id,
                tutor_name: event.tutor_name,
                color: "#7F56D9"
            }));

            return schedule;
        }).flat();
    };

    const calculateStartDate = (day, time) => {
        const dayNumber = daysOfWeek.indexOf(day);
        const currentDate = new Date();
        const currentDayNumber = currentDate.getDay();
        const daystoAdd = dayNumber - currentDayNumber;
        currentDate.setDate(currentDate.getDate() + daystoAdd);
        currentDate.setHours(Number(time.split(':')[0]), Number(time.split(':')[1]), 0, 0);
        return currentDate.toISOString().slice(0, -1) + 'Z';
    };

    const viewWeeklySchedule = () => {
        setLoading(true);
        api.get(API_URL + "/students/view-weekly-schedule/" + id).then((response) => {
            setLoading(false);
            const formattedData = transformData(response.data);
            setWeeklySchedule(formattedData);
        })
        .catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message || error.message); ;
            console.log(error)
        })

    }
    useEffect(() => {
        viewWeeklySchedule();
    }, []);

    return (
        <div className="row">
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
            <div className="col-3 padding-remove mobile">
                <StudentSidebar></StudentSidebar>
            </div>
            <div className="col-9 mobile">
                <Paper>
                    <Scheduler
                        data={weeklySchedule} width={200}
                    >
                        <ViewState
                            currentDate={currentDate} onCurrentDateChange={setCurrentDate}
                        />
                        <EditingState
                            onCommitChanges={commitChanges}
                        />
                        <IntegratedEditing />
                        <WeekView startDayHour={8}
                            endDayHour={24}
                            cellDuration={60}
                        ></WeekView>
                        <Toolbar />
                        <DateNavigator />
                        <TodayButton />
                        <ConfirmationDialog />
                        <Appointments />
                        <AppointmentTooltip
                            showOpenButton
                            showDeleteButton
                        />
                        <AppointmentForm basicLayoutComponent={BasicLayout} dateEditorComponent={TextEditor} textEditorComponent={TextEditor} labelComponent={TextEditor} booleanEditorComponent={TextEditor} radioGroupComponent={TextEditor}>
                        </AppointmentForm >
                    </Scheduler>
                </Paper>
            </div>
        </div>
    )
}

export default StudentCalender;
