import { React, api, API_URL, useState, Button, PuffLoader, override, ToastContainer, toast } from "../../../services/common";
import "./Payment.css";
import { useAuth } from "../../Auth/AuthContext";

function Payment(props) {
    const [loading, setLoading] = useState(false);

    const removeIdKey = (slots) => {
        return Object.entries(slots).reduce((acc, [day, slotArray]) => {
          acc[day] = slotArray.map(({ id, ...rest }) => rest);
          return acc;
        }, {});
      };


    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        const id = localStorage.getItem('uid');
        let result
        setLoading(true)
        try{
            result = await api.post(API_URL + "/payments/make_payment/" + id, {
                amount: props.amount * 100,
                currency: "INR",
                tutor_id: props.tutor_id,
                classes: props.classes,
                course: props.course
            })
        }catch(error){
            props.onPaymentFailure(error.message)
            setLoading(false)
            return
        }
    

        const { amount, id: order_id, currency } = result.data;
        let slots = removeIdKey(props.slots);

        const options = {
            key: "rzp_test_ANCrJG4YVIfOkl", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "Music Seekho",
            description: "Transaction",
            order_id: order_id,
            handler: async function (response) { 
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };
                try {
                    await api.post(`${API_URL}/payments/success_payment/${id}`, data);
                    const classData = {
                        tutor_id: props.tutor_id,
                        classes: props.classes,
                        course: props.course,
                        razorpayPaymentId: response.razorpay_payment_id,
                        validity: props.validity
                    };
                    await api.post(`${API_URL}/students/add-student-classes/${id}`, classData);
                    await api.post(`${API_URL}/students/update-weekly-schedule/${id}`, slots);
                    setLoading(false)
                    props.onPaymentSuccess();

                } catch (error) {
                    props.onPaymentFailure(error.message)
                    setLoading(false)
                }

            },
            prefill: {
                name: "",
                email: "",
                contact: "",
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
            toast.error(`Payment failed: ${response.error.description}`);
            setLoading(false)
        });
        paymentObject.open();
    }
    return (
        <div>
            <PuffLoader loading={loading} cssOverride={override} color="#7F56D9" />
            <ToastContainer position="bottom-left"
                autoClose={2500} theme="colored" />
            <Button className="App-link pay-button" onClick={displayRazorpay}>
                Pay ₹ {props.amount}
            </Button>
        </div>

    );
}

export default Payment;